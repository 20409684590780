import {
    BrowserRouter as Router,
    Routes as Switch,
    Route
} from 'react-router-dom';

// pages
import { Main } from '../pages';

export const Routes = () => <Router>
    <Switch>
        <Route path="/" element={<Main />} />
    </Switch>
</Router>