import axios from "axios";
import { obterParametrosDaURL } from "../../functions";

const X_FRONT_VERSION = "05/08/2024";

export const api = axios.create({
  baseURL:
    "https://apiliberacaodesenha.radinfo.com.br/api/solicitacaosenhaweb/",
    // "http://192.168.1.94:3337/api/solicitacaosenhaweb/",
    // "http://192.168.1.196:5000/notify/api/solicitacaosenhaweb/",
  headers: {
    "X-Front-Version": X_FRONT_VERSION,
  },
});

export const apiRelatorios = axios.create({
  baseURL: "https://liberacaodesenha.radinfo.com.br/relatoriosback/api",
  // baseURL: "http://192.168.1.196:5000/notify/relatorios/api",
  // baseURL: "http://192.168.1.94:5000/api",
  headers: {
    "X-Front-Version": X_FRONT_VERSION,
  },
});

export const apiAssinaturaDigital = axios.create({
  baseURL: "https://api.radinfo.com.br/assinatura_digital/nosso_lar/api",
});

// * DECLARAÇÃO DE INTERCEPTORS

api.interceptors.request.use(
  (config) => {
    const { token } = obterParametrosDaURL();

    if (token && config.headers)
      config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => Promise.reject(error)
);
